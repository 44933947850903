
<template>
<div style="width: 100%;height:100%;background-color: rgb(250,250,250);">
    <div>
        <p class="title">气象服务</p>
        <img src="../../assets/nyimg/qxfw1.jpg" alt="" style="width:100%">
        <ul style="color:#000">
            <li v-for="(item,index) in list" :key="index">
            <div>
                <p class="lititle">{{ item.Title }}</p>
                <div style="display: flex;width:90;padding:10px;flex-wrap: wrap;">
<div v-for="(i,d) in item.sublevel" :key="d" style="text-align: center;width:25%;display: flex;flex-direction:column;align-items:flex-start" @click="open(i.url)">
<img :src="getimg(i)" alt="" style="width: 50%;margin:0 auto">
<span style="text-align: center;margin: 0 auto;">{{ i.Title }}</span>
</div>
                </div>
            </div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import router from '../../router';
import request from "@/utils/request.js";

export default {
  name: '',
  components: {},
  data () {
    return {
        list:[
{
    title:'实况监测',
    list:[
        {
            title:'雷达云图',
            icon:'qxfw2',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=radarChart'
        },
        {
            title:'天气实况',
            icon:'qxfw3',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=metar'
        }
    ]
},{
    title:'预报预警',
    list:[
        {
            title:'天气预报',
            icon:'qxfw4',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=startPages'
        },
        {
            title:'灾害预警',
            icon:'qxfw5',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=ZaiHaiYuJing'
        },
        {
            title:'旅游气象',
            icon:'qxfw6',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=Travel'
        },
        {
            title:'灾情上报',
            icon:'qxfw7',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=ZaiQReport'
        }
    ]
},{
    title:'专业服务',
    list:[
        {
            title:'清廉气象漫画展',
            icon:'qxfw8',
            url:'http://handanwx.qx121.net/MH/manhua.html'
        },
        {
            title:'内涝服务',
            icon:'qxfw9',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=nlServe'
        },
        {
            title:'电力服务',
            icon:'qxfw10',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=dlServe'
        },
        {
            title:'省运会气象服务',
            icon:'qxfw11',
            url:'http://handanwx.qx121.net/api/Wechat/GetUserInfo?urls=ayhServe'
        }
    ]
}
        ]
    }
  },
  created () {
    this.getpagelist()
   },
  mounted () { },
  methods: { 
    getpagelist(){
        
        request({
            url:'/api/Wechat/GetServe_moduleList?openid='+this.$route.query.openid,
      method: "get",
        }).then(res=>{
            console.log(res);
            this.list=res.data
        })
    },
    open(url){
        if(Number(url)){
             router.push('/serve1?id='+url)
        }else{
            location.href=(url)
        }
       
    },
    getimg(i){
        try{

  return  require('@/assets/nyimg/'+i.Title+'.png')
        }catch(e){
            return    require('@/assets/nyimg/灾害预警.png')
        } 
    }
   },
  computed: {}
}
</script>
<style scoped lang='scss'>
p{margin:0}
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.title{
    text-align: center;
    background:rgb(29,99,197);
    padding: 10px 0
}
.lititle{
    font-size: 18px;
    border-bottom:1px solid #EDEDED ;
    padding:10px;
    margin-bottom:10px ;
    font-weight: 690;
    padding-left:15px;
}
li{
    background: #fff;
    margin-top:10px
}
</style>
